import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useGetMSPs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mspData, setMspData] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const getMSPs = async (refresh = false) => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/msp/?refresh=${refresh}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch MSPs');
      const responseData = await response.json();
      setMspData(responseData);
      return responseData;
    } catch (error) {
      setError(error);
      console.error('Error fetching MSPs:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { getMSPs, isLoading, error, mspData };
};

export default useGetMSPs;
