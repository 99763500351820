import React, { useState, forwardRef } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const PasswordInput = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputGroup>
      <Form.Control
        type={showPassword ? 'text' : 'password'}
        {...props}
        ref={ref}
      />
      <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </Button>
    </InputGroup>
  );
});

export default PasswordInput;
