import React from 'react';
import GenericError from './GenericError';
const Error404 = () => {
  return (
    <GenericError code={404} title="The page you're looking for is not found.">
      Make sure the address is correct and that the page hasn't moved. If you
      think this is a mistake,
      <a href="mailto:ahoy@threatcaptain.com" className="ms-1">
        contact us
      </a>
      .
    </GenericError>
  );
};

export default Error404;
