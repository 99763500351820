import React, { useEffect } from 'react';
import uaParser from 'ua-parser-js';
import { Outlet } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useAppContext } from 'providers/AppProvider';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';

ChartJS.register(...registerables);

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useAppContext();

  useEffect(() => {
    const ua = new uaParser();
    const os = ua.getOS()?.name?.toLowerCase() ?? '';
    const browser = ua.getBrowser()?.name?.toLowerCase() ?? '';

    if (os === 'windows') {
      HTMLClassList.add('windows');
    }
    if (browser.includes('chrome')) {
      HTMLClassList.add('chrome');
    }
    if (browser.includes('firefox')) {
      HTMLClassList.add('firefox');
    }
    if (browser.includes('safari')) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  useEffect(() => {
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    if (!trackingId) {
      return;
    }

    const gtagScript = document.createElement('script');
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    gtagScript.async = true;
    document.head.appendChild(gtagScript);

    const gtagConfigScript = document.createElement('script');
    gtagConfigScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingId}');
    `;
    document.head.appendChild(gtagConfigScript);

    // Cleanup scripts when the component unmounts
    return () => {
      document.head.removeChild(gtagScript);
      document.head.removeChild(gtagConfigScript);
    };

  }, []);

  return (
    <Auth0ProviderWithNavigate>
      <Outlet />
    </Auth0ProviderWithNavigate>
  );
};

export default App;
