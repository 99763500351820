export const rootPaths = {
  root: '/',
  clients: 'clients',
  reports: 'reports',
  simulator: 'simulator',
  errorsRoot: 'errors',
  billing: 'billing',
  msp: 'msp',
  feedback: 'feedback',
  accountSettings: 'accountSettings',
  academy: 'academy',
  superAdmin: 'super',
  integrations: 'integrations'
};

export default {
  landing: `${rootPaths.root}`,
  accountSettings: `/${rootPaths.accountSettings}`,
  academy: `/${rootPaths.academy}`,
  clients: `/${rootPaths.clients}`,
  addClient: `/${rootPaths.clients}/add`,
  updateClient: `/${rootPaths.clients}/update/:clientId`,
  clientIntake: `/${rootPaths.clients}/:clientId/intake`,
  clientDetails: `/${rootPaths.clients}/:clientId/details`,
  billing: `/${rootPaths.billing}`,
  billingReturn: `/${rootPaths.billing}/return`,
  addMsp: `/${rootPaths.msp}/add`,
  certificatePage: `/certificatePage`,
  // insights: `/insights`,
  reports: `/reports`,
  // riskAnalsysis: `/risk-analysis`,
  impactSimulator: `/${rootPaths.simulator}`,
  impactSimulatorExport: `/${rootPaths.simulator}/export`,
  integrations: `/${rootPaths.integrations}`,
  likelihoodSimulator: `/${rootPaths.simulator}/likelihood`,
  setupAccount: `/account-setup`,
  superAdminLanding: `/${rootPaths.superAdmin}/`,
  permissions: `/${rootPaths.superAdmin}/permissions`,
  userManagement: `/${rootPaths.superAdmin}/user-management`,
  startTrial: `/${rootPaths.superAdmin}/msp/:mspId/start-trial`,
  editMsp: `/${rootPaths.superAdmin}/msp/:mspId/edit`,
  createMsp: `/${rootPaths.superAdmin}/msp/new`,
  support: `/${rootPaths.superAdmin}/support`,
  error404: `/${rootPaths.errorsRoot}/404`,
  error500: `/${rootPaths.errorsRoot}/500`,
  callback: '/callback',
  signup: '/signup'
};
