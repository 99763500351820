import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const usePermissions = () => {
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const isLoading = isLoadingRoles || isLoadingPermissions;

  const getRoles = async () => {
    setIsLoadingRoles(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/roles`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error(response.statusText);
      const responseData = await response.json();

      const organizedResponseData = responseData.slice().sort((a,b) => {
        const order = ['User', 'Admin', 'Super Admin'];
        return order.indexOf(a.displayName) - order.indexOf(b.displayName);
      });

      setRoles(organizedResponseData);
      return organizedResponseData;
    } catch (error) {
      setError(error);
      console.error('Error fetching roles:', error);
      return null;
    } finally {
      setIsLoadingRoles(false);
    }
  };

  const getPermissions = async () => {
    setIsLoadingPermissions(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/permissions`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error(response.statusText);
      const responseData = await response.json();
      setPermissions(responseData);
      return responseData;
    } catch (error) {
      setError(error);
      console.error('Error fetching permissions:', error);
      return null;
    } finally {
      setIsLoadingPermissions(false);
    }
  };

  const getUserRole = async userId => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/roles/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error(response.statusText);
      return await response.json();
    } catch (error) {
      console.error('Error fetching user role:', error);
      return null;
    }
  };

  const getUserPermissions = async userId => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/permissions/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error(response.statusText);
      return await response.json();
    } catch (error) {
      console.error('Error fetching user permissions:', error);
      return null;
    }
  };

  const setRole = async (userId, role) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/roles/${userId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: role
        }
      );

      if (!response.ok) throw new Error(response.statusText);
      return true;
    } catch (error) {
      console.error('Error changing user role:', error);
      return false;
    }
  };

  const setRolesPermissions = async permissions => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/permissions`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(permissions)
        }
      );

      if (!response.ok) throw new Error(response.statusText);
      return true;
    } catch (error) {
      console.error('Error changing user permissions:', error);
      return false;
    }
  };

  return {
    getRoles,
    getPermissions,
    getUserRole,
    getUserPermissions,
    setRole,
    setRolesPermissions,
    isLoading,
    error,
    roles,
    permissions
  };
};

export default usePermissions;
