import React, { useEffect, useState } from 'react';
//import { clientStatsData } from 'data/msp-clients/clientData';
import ClientStats from 'components/clientsDashboard/client-stats/ClientStats';
import { ClientList } from 'components/clientsDashboard/client-list/ClientList';
import ClientListContext from 'components/clientsDashboard/ClientListContext';
import { ClientListProvider } from 'components/clientsDashboard/ClientListContext';
import useGetClient from 'hooks/useGetClients';
import useSimulations from "hooks/useSimulations";

const Clients = () => {
  const [clientStatsData, setClientStatsData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const { getClients } = useGetClient();
  const { getNewestSim } = useSimulations();

  useEffect(() => {
    const fetchClients = async () => {
      var clientLimit = false;
      try {
        const allClients = await getClients();
        const activeClients = allClients.filter(client => !client.deleted);

        const clientsWithDates = await Promise.all(
          activeClients.map(async (client) => {
              const newestSimData = await getNewestSim(client.id);
              return { ...client, newestSimData }; // Add the date to each client object
          })
        );

        setClientData(clientsWithDates);
        console.log(clientsWithDates);

        const currentDate = new Date();
        const prospectsThisMonth = activeClients.filter(client => {
          const creationDate = new Date(client.creationDate);
          const isProspect = client.prospect === true; // Ensure the client is a prospect
          const isThisMonth =
            creationDate.getMonth() === currentDate.getMonth() &&
            creationDate.getFullYear() === currentDate.getFullYear();
          return isProspect && isThisMonth;
        }).length;

        const totalResponses = activeClients.length;
        const prospectTrueCount = activeClients.filter(
          client => client.prospect
        ).length;

        if (totalResponses - prospectTrueCount >= 10) {
          clientLimit = true;
        }

        const clientLimitJson = JSON.stringify(clientLimit);
        localStorage.setItem('clientLimit', clientLimitJson);

        const statsData = [
          {
            id: 0,
            title: 'Total Accounts',
            amount: totalResponses,
            icon: 'users',
            color: 'primary',
            className:
              'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
          },
          {
            id: 1,
            title: 'New Prospects',
            amount: prospectTrueCount,
            amountLastMonth: prospectsThisMonth,
            icon: 'user',
            color: 'info',
            className:
              'border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0'
          }
        ];

        setClientStatsData(statsData);
        // setClientData(activeClients);
      } catch (error) {
        console.error('Error fetching client stats:', error);
      }
    };

    fetchClients();
  }, []);

  return (
    <ClientListProvider>
      <>
        <ClientStats data={clientStatsData} />
        <ClientList data={clientData} />
      </>
    </ClientListProvider>
  );
};

export default Clients;
