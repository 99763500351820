import { useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from 'components/auth0/AuthContext';

const useGetClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  //replace this with a local storage call
  const user = useContext(AuthContext);
  const mspId = user?.msp?.id;

  const getClients = async () => {
    if (mspId === undefined) return null;
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${mspId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to save client');
      const responseData = await response.json();
      setResponse(responseData);
      return responseData;
    } catch (error) {
      setError(error);
      console.error('Error fetching clients:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { getClients, isLoading, error, response };
};

export default useGetClient;
