import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import Select from 'react-select';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { industries, countries } from 'data/simulators/impactSimulatorData';
import { timezones } from 'data/events/timezones';
import { Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const timezoneOptions = timezones.map(tz => ({
  label: tz,
  value: tz
}));

const GeneralForm = ({ client, register, errors, setValue, control }) => {
  useEffect(() => {
    if (client) {
      setValue('name', client.name || '');
      setValue('prospect', client.prospect + '' || 'false');
      setValue('industry', client.industry || '');
      setValue('country', client.country || '');
      setValue('website', client.website || '');
      setValue('timezone', client.timezone || '');
      if (Array.isArray(client?.contacts) && client?.contacts[0]) {
        setValue('contactName', client.contacts[0].name || '');
        setValue('contactPhone', client.contacts[0].phone || '');
        setValue('contactEmail', client.contacts[0].email || '');
        setValue('contactMethod', client.contacts[0].contactMethod || 'email');
      }
    } else {
      setValue('name', '');
      setValue('prospect', false);
      setValue('industry', '');
      setValue('country', '');
      setValue('website', '');
      setValue('timezone', '');
      setValue('contactName', '');
      setValue('contactPhone', '');
      setValue('contactMethod', 'email');
      const searchParams = new URLSearchParams(location.search);
      const prospect = searchParams.get('prospect');
      if (prospect === 'true') {
        setValue('prospect', 'true');
      }
    }
  }, [location.search, setValue, client]);

  return (
    <>
      <h4>General Information</h4>
      <Row className="g-4 mb-3">
        <p className="subheader">Fill out account's basic Information</p>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="select"
            label="Type of Account"
            name="prospect"
            options={[
              { label: 'Prospect', value: 'true' },
              { label: 'Client', value: 'false' }
            ]}
            errors={errors}
            placeholder="Client or Prospect?"
            formGroupProps={{ className: 'mb-3' }}
            required
            formControlProps={{
              ...register('prospect', {
                required: 'Type of Account is required'
              })
            }}
          />
        </Col>
        <Col md={6}>
          <WizardInput
            type="select"
            label="Industry"
            name="industry"
            options={industries}
            errors={errors}
            placeholder="Select Industry"
            formGroupProps={{ className: 'mb-3' }}
            required
            formControlProps={{
              ...register('industry', {
                required: 'Industry is required'
              })
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Company Name"
            name="name"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            required
            placeholder="Company Name"
            formControlProps={{
              ...register('name', {
                required: 'Company Name is required',
                pattern: {
                  value: /^[A-Z0-9]([a-zA-Z0-9]|[- @\\.#&!',"/()+:;_%$*?^~|])*$/i,
                  message:
                    'Invalid Company Name. Company Name must start with a capital letter and contain only letters, numbers, and special characters (- @.#&!\'")'
                }
              })
            }}
          />
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Country/Region <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Controller
              name="country"
              control={control}
              defaultValue="Country/Region"
              required
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isSearchable={true}
                  name={name}
                  inputRef={ref}
                  value={{
                    label: value?.replace(/([A-Z])/g, ' $1').trim(),
                    value: value
                  }}
                  options={countries.map(c => ({
                    label: c.replace(/([A-Z])/g, ' $1').trim(),
                    value: c
                  }))}
                  placeholder="Country/Region"
                  defaultValue="Country/Region"
                  classNamePrefix="react-select"
                  required
                  onChange={selectedOption => onChange(selectedOption.value)}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors['country']?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Website"
            name="website"
            placeholder="Company Website"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('website')
            }}
          />
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Timezone <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Controller
              name="timezone"
              control={control}
              defaultValue="Select Timezone"
              required
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isSearchable={true}
                  name={name}
                  inputRef={ref}
                  value={{
                    label: value,
                    value: value
                  }}
                  options={timezoneOptions}
                  placeholder="Select Timezone"
                  defaultValue="Select Timezone"
                  classNamePrefix="react-select"
                  required
                  onChange={selectedOption => onChange(selectedOption.value)}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors['timezone']?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="g-4 mb-3">
        <Col>
          <WizardInput
            type="text"
            label="Primary Contact Name"
            name="contactName"
            required
            errors={errors}
            placeholder="First & Last Name"
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactName', {
                required: 'Contact Name is required',
                pattern: {
                  value: /^[a-z ,.'-]+$/i,
                  message: 'Please enter the Contact Name'
                }
              })
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <Controller
            name="contactMethod"
            control={control}
            defaultValue={'email'}
            render={({ field }) => (
              <Button
                name="contactMethod"
                ref={field.ref}
                type="button"
                onClick={() => field.onChange('phone')}
                className="btn-link text-decoration-none px-0"
                style={{
                  fontSize: '0.8rem',
                  float: 'right',
                  backgroundColor: 'transparent',
                  ...(field.value === 'phone'
                    ? { opacity: 1 }
                    : { opacity: 0.6 })
                }}
              >
                <FontAwesomeIcon icon="star" className="me-2" />
                Preferred contact method
              </Button>
            )}
          />
          <WizardInput
            type="text"
            label="Contact Phone #"
            name="contactPhone"
            required
            errors={errors}
            placeholder="Phone #"
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactPhone', {
                required: 'Contact Phone is required'
              })
            }}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="contactMethod"
            control={control}
            defaultValue={'email'}
            render={({ field }) => (
              <Button
                name="contactMethod"
                ref={field.ref}
                type="button"
                onClick={() => field.onChange('email')}
                className="btn-link text-decoration-none px-0"
                style={{
                  fontSize: '0.8rem',
                  float: 'right',
                  backgroundColor: 'transparent',
                  ...(field.value === 'email'
                    ? { opacity: 1 }
                    : { opacity: 0.6 })
                }}
              >
                <FontAwesomeIcon icon="star" className="me-2" />
                Preferred contact method
              </Button>
            )}
          />
          <WizardInput
            type="email"
            errors={errors}
            label="Contact Email"
            name="contactEmail"
            placeholder="Email Address"
            required
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactEmail', {
                required: 'Contact Email is required',
                pattern: {
                  value:
                    /[A-Za-z0-9._%+-]{3,}@[A-Za-z0-9]{3,}(\.[A-Za-z]{2,}|\.[A-Za-z]{2,}\.[A-Za-z]{2,})/i,
                  message: 'Email must be valid'
                }
              })
            }}
          />
        </Col>
      </Row>
    </>
  );
};

GeneralForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  client: PropTypes.object,
  control: PropTypes.object
};

export default GeneralForm;
