import React, { useContext, useEffect } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext } from 'components/auth0/AuthContext';
import useUser from 'hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PasswordInput from 'components/common/PasswordInput';
import './CreateAccount.css';
import paths from 'routes/paths';

const schema = yup
  .object({
    username: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_]+$/,
        'Username must not contain "@" or special characters.'
      )
      .required('This field is required.'),
    password: yup.string().required('This field is required.'),
    confirmPassword: yup
      .string()
      .required('This field is required.')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })
  .required();

const CreateAccount = () => {
  const user = useContext(AuthContext);
  const { isCreatingAccount, creatingAccount } = useUser();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async data => {
    const toastId = toast.loading('Setting password...');
    const result = await creatingAccount(data);
    const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
    if (result && result.ok) {
      toast.update(toastId, {
        render: 'Password created successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000 // Automatically close after 5 seconds
      });
      await wait(1000);
      navigate(paths.landing);
    } else {
      let message;
      try {
        message = (await result.clone().json()).message;
      } catch (error) {
        message = await result.text();
      }

      if (!message) {
        message = 'An error occurred. Please try again later.';
      }

      toast.update(toastId, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000 // Automatically close after 5 seconds
      });
    }
  };

  const defaultUsername = user?.email
    .split('@')[0]
    .replace(/[^a-zA-Z0-9]/g, '');

  return (
    <>
      <ToastContainer />
      <div className="login-container">
        <div className="login-box">
          <div className="graphic-one">
            <img src="/img/circle-graphic-1.png" />
          </div>
          <div className="graphic-two">
            <img src="/img/circle-graphic-2.png" />
          </div>
          <div className="left-login-col">
            <img id="background-overlay" src="/img/half-circle-design.png" />
            <div className="login-message-inner">
              <img
                id="auth-logo"
                src="https://img1.wsimg.com/isteam/ip/5c27002e-0e59-4e6d-b50f-cd6896df6d8c/Threat-4%2520(2).png"
              />
              <p>
                Set a password to protect your account. You can use this
                password for login as well as the social login options.
              </p>

              <div id="bottomLeftText">
                <p>You are logged in as</p>
                <p style={{ fontWeight: 'bold' }}>{user?.name}</p>
                <p id="tos">
                  Read our <a href="#">terms and conditions</a>
                </p>
              </div>
            </div>
          </div>
          <div className="right-login-col">
            <div id="loginForm">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <h2>Account Setup</h2>
                <Row className="g-3 mb-3">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.username}
                        {...register('username')}
                        placeholder="Username"
                        defaultValue={defaultUsername}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="g-3 mb-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <PasswordInput
                        isInvalid={!!errors.password}
                        {...register('password')}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="g-3 mb-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="confirm-password">
                      <Form.Label>Confirm Password</Form.Label>
                      <PasswordInput
                        isInvalid={!!errors.confirmPassword}
                        {...register('confirmPassword')}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100"
                      disabled={isCreatingAccount}>
                      {isCreatingAccount ? 'One Moment...' : 'Create'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateAccount;
