import React, { useContext, useEffect, useState } from 'react';
import { Card, Table, Form, Button } from 'react-bootstrap';
import { AuthContext } from 'components/auth0/AuthContext';
import Spinner from 'react-bootstrap/Spinner';
import GenericError from 'components/errors/GenericError';
import usePermissions from 'hooks/usePermissions';
import { ToastContainer, toast } from 'react-toastify';
import useConfirmDialog from 'components/common/ConfirmDialog';
import 'react-toastify/dist/ReactToastify.css';

const Permissions = () => {
  const user = useContext(AuthContext);
  const userPermissions = user?.permissions ?? [];
  const {
    getRoles,
    getPermissions,
    setRolesPermissions,
    isLoading,
    error,
    roles,
    permissions
  } = usePermissions();
  const { ConfirmDialog, confirm } = useConfirmDialog();
  const [selectedRolePermission, setSelectedRolePermission] = useState({});

  useEffect(() => {
    Promise.all([getRoles(), getPermissions()]).then(([roles, permissions]) => {
      const newSelectedRolePermission = {};
      roles.forEach(role => {
        newSelectedRolePermission[role.id] = role.permissions.map(
          permissionName =>
            permissions.find(permission => permission.name === permissionName)
              .id
        );
      });
      setSelectedRolePermission(newSelectedRolePermission);
    });
  }, []);

  useEffect(() => {
    if (error) {
      toast.error('Error fetching permissions');
    }
  }, [error]);

  if (
    !userPermissions.includes('super_admin_dashboard') ||
    !userPermissions.includes('change_permissions')
  ) {
    return (
      <GenericError code={403} title="Forbidden">
        You do not have permission to access this page.
      </GenericError>
    );
  }

  const onChange = (roleId, permissionId) => {
    const newSelectedRolePermission = { ...selectedRolePermission };
    if (newSelectedRolePermission[roleId].includes(permissionId)) {
      newSelectedRolePermission[roleId] = newSelectedRolePermission[
        roleId
      ].filter(id => id !== permissionId);
    } else {
      newSelectedRolePermission[roleId].push(permissionId);
    }

    console.log(newSelectedRolePermission);
    setSelectedRolePermission(newSelectedRolePermission);
  };

  const willRemoveItsOwnPermission = () => {
    const currentRole = roles.find(({ name }) => name === user?.role);
    console.log(currentRole);
    if (!currentRole) return false;
    const dashboardPermission = permissions.find(
      ({ name }) => name === 'super_admin_dashboard'
    );
    const changePermissionsPermission = permissions.find(
      ({ name }) => name === 'change_permissions'
    );
    return (
      !selectedRolePermission[currentRole.id].includes(
        dashboardPermission.id
      ) ||
      !selectedRolePermission[currentRole.id].includes(
        changePermissionsPermission.id
      )
    );
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    ev.stopPropagation();
    if (
      !willRemoveItsOwnPermission() ||
      (await confirm(
        'You are about to remove your own permission to access this page. Are you sure you want to continue?',
        'Remove own permission',
        'Remove',
        'Cancel'
      ))
    ) {
      console.log('Saving permissions...');
      const permissionsRequest = Object.entries(selectedRolePermission).map(
        ([roleId, permissionIds]) => ({
          roleId,
          permissionIds
        })
      );
      await toast.promise(setRolesPermissions(permissionsRequest), {
        pending: 'Updating permissions...',
        success: 'Permissions updated successfully',
        error: 'An error occurred. Please try again later.'
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <ConfirmDialog />
      <Card as={Form} onSubmit={handleSubmit}>
        <Card.Body>
          <Card.Title>Permissions</Card.Title>
          <Card.Subtitle className="mb-2">
            Manage user permissions
          </Card.Subtitle>
          {isLoading ? (
            <Spinner animation="border" size="xl" role="Status">
              <span className="visually-hidden">Fetching permissions...</span>
            </Spinner>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    {roles.map(role => (
                      <th key={role.id}>{role.displayName}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {permissions.map(permission => (
                    <tr key={permission.id}>
                      <th>{permission.displayName}</th>
                      {roles.map(role => (
                        <td key={role.id}>
                          <div className="d-flex justify-content-center">
                            <Form.Check
                              type="checkbox"
                              id={`${role.id}-${permission.id}`}
                              label=""
                              checked={selectedRolePermission[role.id].includes(
                                permission.id
                              )}
                              onChange={() => onChange(role.id, permission.id)}
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="text-end">
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Permissions;
