import React, { useContext } from 'react';
import NavbarDropdown from './NavbarDropdown';
import { navRoutes, adminRoutes } from 'routes/siteMaps';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import { AuthContext } from 'components/auth0/AuthContext';

const NavbarTopDropDownMenus = () => {
  const user = useContext(AuthContext);
  const userPermissions = user?.permissions ?? [];
  const {
    config: { navbarCollapsed, showBurgerMenu, superAdminDashboard },
    setConfig
  } = useAppContext();

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const routes =
    superAdminDashboard && userPermissions.includes('super_admin_dashboard')
      ? adminRoutes
      : navRoutes;
  return (
    <>
      <NavbarDropdown title="dashboard">
        {routes.children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
