import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths, { rootPaths } from 'routes/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

import 'assets/scss/theme/page-styles/_supportSettings.scss';
import lifeRing from 'assets/img/icons/menu-icons/life-ring.svg';

const FaqItem = ({ question, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="faq-item mt-2">
      <h5 className="faq-header mt-2" onClick={toggleOpen} style={{ cursor: 'pointer' }}>
        {question}
        <span className="ms-2">
          {isOpen ? '▼' : '▶'}
        </span>
      </h5>
      {isOpen && (
        <div className="faq-content mt-3">
          {children}
        </div>
      )}
    </div>
  );
};

const SupportSettings = () => (
  <div className="support-settings">
    <div className="support-content">
      <h5 className="fw-bold mb-3">Need Help?</h5>
      <p style={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src={lifeRing}
          alt="Support Icon" 
          className="inline-logo" 
          style={{ marginRight: '0.5rem' }}
        /> 
        <strong className='fw-bold'>Contact our Support Team: </strong>&nbsp;<a href="mailto:ahoy@threatcaptain.com">ahoy@threatcaptain.com</a>
      </p>
      <Card>
        <Card.Header style={{ background: '#D8E2EF' }}>
          <h4 className="m-0">Frequently Asked Questions</h4>
        </Card.Header>
        <Card.Body className="p-3">
          <div className="faq-section">
            <FaqItem question="How does ThreatCaptain's pricing work?">
              <h6 className="mt-3">Discovery Partner Plan:</h6>
              <ul className="page-text">
                <li>$499/mo (Restrictions may apply)</li>
              </ul>
              <h6 className="mt-3">What’s Included:</h6>
              <ul className="page-text">
                <li><strong>Financial Impact Analysis:</strong>Understand the potential cost of a breach, the chances of it happening, and the overall risk based on your current security setup.</li>
                <li><strong>Detailed Reports:</strong>Clear, easy-to-share reports on your cybersecurity risks.</li>
                <li><strong>Client Dashboard:</strong>A simple overview to track and manage client security.</li>
                <li><strong>Training Resources:</strong>Access to a library of helpful cybersecurity lessons and tips.</li>
                <li><strong>Guided Onboarding:</strong>A smooth setup process to get started quickly.</li>
                <li><strong>Secure Login Options:</strong>Single Sign-On (SSO) and Multi-Factor Authentication (MFA) for added protection.</li>
              </ul>
              <h6 className="mt-3">For more information, contact: <a href='mailto:ahoy@threatcaptain.com'>Ahoy@ThreatCaptain.com</a></h6>
            </FaqItem>
            <FaqItem question="How do the ThreatCaptain Simulations work?" className="force-margin">
              <ol className="page-text">
                <li>
                  Simulation Setup
                  <ul className="page-text">
                    <li>Input Parameters: Clients start by filling out an intake form entering key details such as security controls, business readiness, the industry, breach type (e.g., malicious, accidental), country/region, and estimated record loss. These inputs define the scope and specifics of the simulation.</li>
                  </ul>
                </li>
                <li>
                  Running the Simulation
                  <ul className="page-text">
                    <li>Monte Carlo Simulation: ThreatCaptain uses advanced <strong>Monte Carlo Simulation</strong> techniques to model thousands of possible breach scenarios based on the input parameters and using the <strong>MITRE ATT&CK Framework</strong> and <strong>Verizon Data Breach Investigations Report (DBIR)</strong>. This approach helps predict the range of potential financial losses a client might face in the event of a breach.</li>
                  </ul>
                </li>
                <li>
                  Report Generation
                  <ul className="page-text">
                    <li>
                      Comprehensive Data Analysis: Once the simulation is complete, ThreatCaptain generates a report that includes a detailed breakdown of the potential financial impact. This includes:
                      <ul className="page-text">
                        <li>Business Disruption: Costs related to business disruption, lost customers, and reputation damage.</li>
                        <li>Detection & Escalation: Expenses incurred for forensic investigations, assessments, and audits.</li>
                        <li>Notification Costs: Costs for informing affected parties and meeting regulatory requirements.</li>
                        <li>Ex-Post Costs: Long-term costs, such as legal fees, regulatory fines, and customer support services.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Visualizations and Breakdown
                  <ul className="page-text">
                    <li>Graphs and Charts: The report provides visualizations, such as pie charts and histogram, that illustrate the distribution of costs across different categories (e.g., lost business, detection, notification).</li>
                    <li>Cost Breakdown: A detailed breakdown of costs helps MSPs understand where the most significant financial risks lie.</li>
                  </ul>
                </li>
                <li>
                  Actionable Insights
                  <ul className="page-text">
                    <li>Strategy Recommendations: The report also includes recommendations for enhancing cybersecurity strategies based on the simulation results, allowing MSPs to better protect their clients and reduce potential financial losses.</li>
                  </ul>
                </li>
                <li>
                  Exporting Reports
                  <ul className="page-text">
                    <li>Easy Access: Users can export the reports in a PDF for sharing with clients or for internal review, making it easier to communicate the findings and take necessary actions.</li>
                  </ul>
                </li>
              </ol>
            </FaqItem>
            <FaqItem question="How should MSPs successfully use the simulation reports?">
              <h6 className="mt-3">During Prospecting:</h6>
              <ul className="page-text">
                <li>Demonstrate Expertise: Showcase an understanding of the current threat landscape and the financial implications of cyber risks.</li>
                <li>Quantify Risk: Provide concrete data on potential financial impact and likelihood of cyber incidents specific to the prospect's industry.</li>
                <li>Tailor Solutions: Recommend customized security solutions addressing the most significant risks for the prospect.</li>
              </ul>
              <h6 className="mt-3">During Quarterly Business Reviews (QBRs):</h6>
              <ul className="page-text">
                <li>Review Performance: Assess the effectiveness of current security measures by comparing past simulations with actual incidents.</li>
                <li>Update Risk Assessments: Use updated Monte Carlo simulations to reflect changes in the threat landscape or the client's business environment.</li>
                <li>Strategic Planning: Help clients prioritize their security investments based on the latest risk and impact data, ensuring resource allocation where most needed.</li>
              </ul>
            </FaqItem>
            <FaqItem question="Do you offer guides and trainings?">
              <p className="page-text">
                Yes, we do! Our team is actively working on adding these resources to the dashboard. Meanwhile, you can download a PDF of the detailed guides and training materials by clicking the link below to help you get the most out of our platform.
                <br/><br/><a href='/files/Training Program Outline_ Teaching MSPs to Use Financial Cyber Impact Analysis.pdf' download>ThreatCaptain Training Guide</a>
              </p>
            </FaqItem>
            <FaqItem question="What is your cancellation policy?">
              <p className="page-text">
                We offer 12- or 36-month contracts, with the option to cancel by providing a 30-day notice before the renewal date.
              </p>
            </FaqItem>
          </div>
        </Card.Body>
      </Card>
    </div>
    <div className="d-flex justify-content-center mt-4">
      <Button
        as={Link}
        to={`../${rootPaths.feedback}`}
        className="btn-lg w-100"
        style={{ backgroundColor: '#00D27A', borderColor: '#00D27A' }}
        onMouseOver={e => {
          e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.2)';
        }}
        onMouseOut={e => {
          e.currentTarget.style.boxShadow = 'none';
        }}
      >
        <FontAwesomeIcon
          className="fa-icon mt-1 me-3"
          icon={faComments}
        />
        Give Feedback
      </Button>
    </div>
  </div>
);

export default SupportSettings;
