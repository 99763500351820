import React, { useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Button, Card, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import useTrial from 'hooks/useTrial';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const StartTrial = () => {
  const { startTrial, isSubmitting } = useTrial();
  const navigate = useNavigate();
  const mspId = useParams().mspId ?? null;
  const [formData, setFormData] = useState({
    trialDays: 90
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    const toastId = toast.loading('Starting trial...');
    const response = await startTrial(mspId, formData.trialDays);
    if (response.ok) {
      toast.update(toastId, {
        render: 'Trial Started successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000 // Automatically close after 5 seconds
      });
      navigate('/super/user-management');
    } else {
      console.error('Failed to start trial');
      let message = 'An error occurred. Please try again later.';
      switch (response.status) {
        case 400:
          message = 'Bad values provided. Please try again.';
          break;
        case 404:
          message = 'MSP ID not found. Please try again.';
          break;
        case 409:
          message =
            'Customer details not found. Please edit the customer details for the MSP and try again.';
          break;
        case 500:
          message = await response.text();
          if (!message) {
            message = 'An error occurred. Please try again later.';
          }
          break;
      }

      toast.update(toastId, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000 // Automatically close after 5 seconds
      });
    }
  };

  if (!mspId) {
    return (
      <Card className="mb-3">
        <FalconCardHeader title="Start Trial" />
        <Card.Body className="bg-body-tertiary">
          <p className="text-danger">MSP ID not found!</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <ToastContainer />
      <Card className="mb-3 col-4 m-auto">
        <FalconCardHeader title="Start Trial" />
        <Card.Body className="bg-body-tertiary">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Trial Days</Form.Label>
              <Form.Control
                type="number"
                value={formData.trialDays}
                name="trialDays"
                onChange={handleChange}
              />
            </Form.Group>
            <Button className="w-100" type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Start Trial'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default StartTrial;
