import React from 'react';
import '../assets/scss/theme/helpers/_waitingCard.scss';
import captainCap from '../assets/img/threat-captain-logos/PhotoCap.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const WaitingCard = ({ type, status }) => {
    let loaderType = {
        title: 'Loading...',
        second: 'Did You Know?',
        third: 'The Verizon Data Breach Investigations Report collects insights from thousands of incidents, often over 80 countries worldwide'
    }

    switch(type) {
        case 'impact':
            loaderType.title = 'Impact Simulation Deployed!';
            loaderType.second = 'Data Sources: MITRE ATT&CK Framework and Verizon Data Breach Investigations Report (DBIR)';
            loaderType.third = 'Generating breach estimates tailored to your industry...';
            break;

        case 'likelihood':
            loaderType.title = 'Likelihood Analysis in Progress';
            loaderType.second = 'Analyzing Threat Patterns and Historical Data';
            loaderType.third = 'Calculating breach probability based on industry trends and threat behavior...';
            break;

        case 'user':
            loaderType.title = 'Updating your user information';
            loaderType.second = 'Did You Know?';
            loaderType.third = 'You can set up Multi-Factor Authentification (MFA) from the Security Settings tab?';
            break;

        default:
            break;
    }

    return(
        <>
            {(status === 'error') ? <>
                <div className='loader-overlay'>
                    <div className='loader-card'>
                        <div className='logo-container'>
                            <FontAwesomeIcon 
                                className='loader-icon'
                                icon={faTriangleExclamation}
                                color='#E63757'
                            />
                        </div>
                        <h3>Oh no! Looks like we’re a bit off course!</h3>
                        <h5>There was an error processing your request</h5>
                        <h6>Let's find better waters and try again later.</h6>
                    </div>
                </div>
            </> : <>
                {(status === 'success') ? <>
                    <div className='loader-overlay'>
                        <div className='loader-card'>
                            <div className='logo-container'>
                                <FontAwesomeIcon 
                                    className='loader-icon'
                                    icon={faCircleCheck}
                                    color='#00D27A'
                                />
                            </div>
                            <h3 style={{ fontWeight: 'bolder' }}>Success!</h3>
                            <h5 style={{ paddingTop: '0.5rem' }}>It's all calm waters ahead</h5>
                        </div>
                    </div>
                </> : <>
                    <>
                        <div className='loader-overlay'>
                            <div className='loader-card'>
                                <div className='logo-container'>
                                    <img src={captainCap} alt='Loading...' className='loader-icon loader-rotation' />
                                </div>
                                <h3 style={{ fontWeight: 'bolder' }}>{loaderType.title}</h3>
                                <h5 style={{ paddingTop: '0.5rem' }}>{loaderType.second}</h5>
                                <p style={{ fontStyle: 'italic', paddingTop: '0.25rem' }}>{loaderType.third}</p>
                            </div>
                        </div>
                    </>
                </>}
            </>}
        </>
    );
}

export default WaitingCard;
