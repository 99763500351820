import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import useGetClient from 'hooks/useGetClients';
import { useNavigate } from 'react-router-dom';
import paths, { rootPaths } from 'routes/paths';

import lifeRing from '../../assets/img/icons/menu-icons/life-ring.svg';
import messageIcon from '../../assets/img/icons/menu-icons/messages-icons.svg';

import '../../assets/scss/theme/page-styles/_dashboard.scss';
import '../../assets/scss/theme/helpers/_card-backgrounds.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faComments,
  faUsers,
  faBullseye,
  faChartLine,
  faUserPlus,
  faLock,
  faBook,
  faQuestion,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  const navigate = useNavigate();
  const { getClients } = useGetClient();
  const [clientsTotal, setClientTotal] = useState(0);
  const [prospectTotal, setProspectTotal] = useState(0);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const allClients = await getClients();
        const activeClients =
          allClients?.filter(client => !client.deleted) ?? [];

        const totalResponses = activeClients.length;
        const prospectTrueCount = activeClients.filter(
          client => client.prospect
        ).length;

        setClientTotal(totalResponses - prospectTrueCount);
        setProspectTotal(prospectTrueCount);
      } catch (error) {
        console.error('Error fetching client stats:', error);
      }
    };

    fetchClients();
  }, []);

  const gridItems = [
    {
      id: 1,
      title: 'Simulator',
      description: 'Generate a financial risk analysis',
      icon: faChartLine,
      link: () => navigate(paths.impactSimulator)
    },
    {
      id: 2,
      title: 'New Account',
      description: 'Add new accounts quickly',
      icon: faUserPlus,
      link: () => navigate(paths.addClient)
    },
    {
      id: 3,
      title: 'Secure your Account',
      description:
        'Boost your security by turning on Multi-Factor Authentication',
      icon: faLock,
      link: () =>
        navigate(paths.accountSettings, { state: { selectedTab: 'security' } })
    },
    {
      id: 4,
      title: 'Training & Guides',
      description:
        'Unlock expert insights and tools to enhance your experience',
      icon: faBook,
      link: () => navigate(rootPaths.academy)
    },
    {
      id: 5,
      title: 'Send Feedback',
      description:
        'Share your thoughts on features, navigation, or your overall experience. Your feedback drives our evolution.',
      icon: faComments,
      link: () => navigate(rootPaths.feedback)
    },
    {
      id: 6,
      title: 'Need Help',
      description:
        'Send us an email to ahoy@threatcaptain.com, we will help you',
      icon: faEnvelope,
      link: () => (window.location.href = `mailto:ahoy@threatcaptain.com`)
    }
  ];

  return (
    <Container fluid className="py-4 px-0">
      <Row className="mb-4">
        <Col xs={12} md={12}>
          <Card className="bg-corner-2-card welcome-card">
            <Card.Body>
              <h2 className="m-0">
                <span className="text-primary">Welcome to </span>
                <span className="text-primary fw-bold">THREAT</span>
                <span className="text-lighter-primary">CAPTAIN</span>
                <span className="text-primary">!</span>
              </h2>
              <p className="mt-1 mb-0 fw-bold text-secondary">
                Here are some quick links for you to start
              </p>

              <div className="grid-container dashboard-grid">
                {gridItems.map(item => (
                  <div key={item.id} className="grid-item" onClick={item.link}>
                    {/* <img src={item.icon} alt={`Icon for ${item.title}`} className="item-icon" /> */}
                    <FontAwesomeIcon
                      className="fa-icon mt-1 me-3"
                      icon={item.icon}
                      style={{
                        fontSize: '2.5rem',
                        color: '#086CD9',
                        width: '2rem',
                        height: '2rem',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '2px solid #D8E2EF',
                        borderRadius: '25%',
                        padding: '0.5rem'
                      }}
                    />
                    <div className="item-text">
                      <h5>{item.title}</h5>
                      <p className="text-secondary">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>

          {/* Nested Row for two cards side by side */}
          <Row className="mt-3">
            {/* First card in the nested row */}
            <Col xs={6}>
              <Card className="bg-corner-4-card">
                <Card.Body>
                  <h5 className="mb-3">
                    <FontAwesomeIcon
                      className="fa-icon mt-1 me-3 fa-lg"
                      icon={faUsers}
                      style={{ color: '#00D27A' }}
                    />
                    Clients
                  </h5>
                  <h2 style={{ color: '#00D27A' }}>
                    {clientsTotal}{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      client{clientsTotal == 1 ? '' : 's'}
                    </span>
                  </h2>
                </Card.Body>
              </Card>
            </Col>

            {/* Second card in the nested row */}
            <Col xs={6}>
              <Card className="bg-corner-1-card">
                <Card.Body>
                  <h5 className="mb-3">
                    <FontAwesomeIcon
                      className="fa-icon mt-1 me-3 fa-lg"
                      icon={faBullseye}
                      style={{ color: '#F5803E' }}
                    />
                    Unlimited Prospects
                  </h5>
                  <h2 style={{ color: '#F5803E' }}>
                    {prospectTotal}{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      prospect{prospectTotal == 1 ? '' : 's'}
                    </span>
                  </h2>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* <Col xs={12} md={5}>
          <Card className="mt-4 mt-md-0">
            <Card.Body className="dashboard-info-card">
              <h3 className="mb-3">Getting Started</h3>

              <div>
                <h5 className="fw-bold">Explore Key Features</h5>
                <ul className="custom-list">
                  <li>
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        className="fa-icon mt-1 me-3"
                        icon={faCheck}
                        style={{ color: '#00D27A' }}
                      />
                      <div>
                        <p className="mb-0">
                          <span className="fw-bold">Feature 1</span> - Simulator
                        </p>
                        <p className="mb-0">
                          Find it under the quick links or the "Simulator" tab
                          on the navigation bar.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        className="fa-icon mt-1 me-3"
                        icon={faCheck}
                        style={{ color: '#00D27A' }}
                      />
                      <div>
                        <p className='mb-0'><span className='fw-bold'>Feature 2</span> - Manage your Accounts</p>
                        <p className='mb-0'>
                          Access this feature under the “Organizations” tab on the navigation bar. You can also create new clients or prospects through the quick links.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <h5 className="fw-bold">Need Help?</h5>
                <div className="d-flex align-items-start get-started-section-container">
                  <img src={lifeRing} alt="Logo 2" className="inline-logo" />
                  <div>
                    <p className="mb-0 fw-bold">Contact our Support Team:</p>
                    <p className="mb-0">ahoy@threatcaptain.com</p>
                  </div>
                </div>
              </div>

              <div>
                <h5 className="fw-bold">We Value Your Feedback!</h5>
                <div className="d-flex align-items-start get-started-section-container">
                  <p>
                    <img
                      src={messageIcon}
                      alt="Logo 2"
                      className="inline-logo"
                    />{' '}
                    Share your thoughts on features, navigation, or your overall
                    experience. Your feedback drives our evolution.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-auto">
                <Button
                  as={Link}
                  to={rootPaths.feedback}
                  className="btn-lg w-100"
                  style={{ backgroundColor: '#ffffff', borderColor: '#086CD9', color: '#086CD9' }}
                  onMouseOver={e => {
                    e.currentTarget.style.boxShadow =
                      '0 0 10px rgba(0, 0, 0, 0.2)';
                  }}
                  onMouseOut={e => {
                    e.currentTarget.style.boxShadow = 'none';
                  }}
                >
                  <FontAwesomeIcon
                    className="fa-icon mt-1 me-3"
                    icon={faComments}
                  />
                  Give Feedback
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </Container>
  );
};
export default Dashboard;
