import React, { useContext } from 'react';
import { useAppContext } from 'providers/AppProvider';
import { AuthContext } from 'components/auth0/AuthContext';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';

const SuperAdminToggleNavItem = () => {
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const userPermissions = user?.permissions ?? [];
  const {
    config: { superAdminDashboard },
    setConfig
  } = useAppContext();

  if (!userPermissions.includes('super_admin_dashboard')) {
    return '';
  }

  const handleSuperAdminToggle = e => {
    if (e.target.checked) {
      setConfig('superAdminDashboard', true);
      navigate(paths.superAdminLanding);
    } else {
      setConfig('superAdminDashboard', false);
      navigate(paths.landing);
    }
  };

  return (
    <Form.Switch
      style={{
        color: superAdminDashboard ? '#2c7be5' : 'inherit',
        fontSize: '1.4em',
        margin: '0.2em 1em 0 0'
      }}
      reverse
      id="super-admin-dashboard"
      label={superAdminDashboard ? 'Captain View' : 'Client View'}
      checked={superAdminDashboard}
      onChange={e => handleSuperAdminToggle(e)}
    />
  );
};

export default SuperAdminToggleNavItem;
