import React, { useEffect } from 'react';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useNavigate } from 'react-router-dom';
import { Card, Dropdown, Button } from 'react-bootstrap';
import useGetMSPs from 'hooks/useGetMSPs';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import CardDropdown from 'components/common/CardDropdown';
import Spinner from 'react-bootstrap/Spinner';
import paths from 'routes/paths';

const statusDescription = {
  PENDING_SUBSCRIPTION: 'Pending Subscription',
  ACTIVE: 'Active',
  ON_HOLD: 'On Hold',
  SUSPENDED: 'Suspended',
  CANCELLED: 'Cancelled'
};

const UserManagement = () => {
  const { getMSPs, isLoading, error, mspData } = useGetMSPs();
  const navigate = useNavigate();

  useEffect(() => {
    getMSPs().catch(() => console.error(error));
  }, []);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
      meta: {
        headerProps: { className: 'pe-1 sticky-column text-900' },
        cellProps: {
          className: 'fw-semibold'
        }
      },
      cell: ({ row: { original } }) => {
        const { name } = original;
        return <span>{name}</span>;
      }
    },
    {
      accessorKey: 'subscriptionStatus',
      header: 'Subscription Status',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: ({ row: { original } }) => {
        return (
          <span>
            {statusDescription[original.subscriptionStatus] ?? 'Unknown Status'}
          </span>
        );
      }
    },
    {
      accessorKey: 'subscriptionStart',
      header: 'Subscription Start',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: ({ row: { original } }) => {
        return (
          <span>
            {original.subscriptionStart
              ? new Date(original.subscriptionStart).toLocaleDateString()
              : 'N/A'}
          </span>
        );
      }
    },
    {
      accessorKey: 'trialend',
      header: 'Trial Until',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: { className: 'trial-date' }
      },
      cell: ({ row: { original } }) => {
        return (
          <span>
            {original.trialEnd
              ? new Date(original.trialEnd).toLocaleDateString()
              : 'Not in trial'}
          </span>
        );
      }
    },
    {
      accessorKey: 'totalSimulationsRun',
      header: 'Simulations Run',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: ({ row: { original } }) => {
        return (
          <span>
            {original.totalSimulationsRun ?? 'No simulations run yet'}
          </span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: '',
      enableSorting: false,
      meta: {
        cellProps: {
          className: 'text-end py-2'
        }
      },
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <CardDropdown drop="start">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(paths.editMsp.replace(':mspId', id))}
              >
                Edit details
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate(paths.startTrial.replace(':mspId', id))}
              >
                Start free trial
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: mspData,
    columns,
    selection: true,
    sortable: true,
    pagination: true,
    perPage: 10
  });

  return (
    <AdvanceTableProvider {...table}>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h5 className="mb-0">Account Management</h5>
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                alignItems: 'center'
              }}
            >
              <Button
                variant="primary"
                className="btn-sm"
                onClick={() => getMSPs(true).catch(() => console.error(error))}
              >
                Refresh
              </Button>
              <Button
                variant="primary"
                className="btn-sm"
                onClick={() => navigate(paths.createMsp)}
              >
                Create New MSP
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {isLoading ? (
            <div className="text-center py-3">
              <Spinner animation="border" size="xl" role="Status">
                <span className="visually-hidden">Fetching MSPs...</span>
              </Spinner>
            </div>
          ) : (
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: 'sm',
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination />
        </Card.Footer>
      </Card>
    </AdvanceTableProvider>
  );
};

export default UserManagement;
