import React from 'react';
import { Card } from 'react-bootstrap';

import '../../assets/scss/theme/page-styles/_integrationsPage.scss';

const IntegrationsHome = () => {
  return (
    <Card>
      <div className="integrations-container">
        <Card className="integration-card">
          <h2>Test 1</h2>
        </Card>
        <Card className="integration-card">
          <h2>Test 2</h2>
        </Card>
        <Card className="integration-card">
          <h2>Test 3</h2>
        </Card>
        <Card className="integration-card">
          <h2>Test 4</h2>
        </Card>
      </div>
    </Card>
  );
};

export default IntegrationsHome;
