import React, { useState, useEffect } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Button, Card, Form, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordInput from 'components/common/PasswordInput';
import TooltipBadge from 'components/common/TooltipBadge';
import countyCodes from 'data/countyCodes';

import * as yup from 'yup';
import useUser from 'hooks/useUser';
import useGetMSPs from 'hooks/useGetMSPs';

const schema = yup.object({
  name: yup.string().required('This field is required.'),
  email: yup.string().required('This field is required.'),
  phone: yup.string(),
  username: yup
    .string()
    .matches(
      /^[a-zA-Z0-9_]+$/,
      'Username must not contain "@" or special characters.'
    )
    .required('This field is required.'),
  password: yup.string().required('This field is required.'),
  confirmPassword: yup
    .string()
    .required('This field is required.')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  country: yup.string().required('This field is required.'),
  postalCode: yup.string(),
  isAdmin: yup.boolean(),
  msp: yup
    .object({
      id: yup.string(),
      name: yup.string(),
      address: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zipcode: yup.string()
    })
    .test('id-or-name', 'Either id or name is required', function (value) {
      return value.id || value.name;
    })
});

const CreateMsp = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addUserWithPassword } = useUser();
  const { getMSPs, isLoading, error, mspData } = useGetMSPs();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const isAdmin = watch('isAdmin');
  useEffect(() => {
    if (!isAdmin && mspData.length === 0) {
      getMSPs().catch(() => console.error(error));
    }
  }, [isAdmin]);

  const onSubmit = async values => {
    const toastId = toast.loading('Creating User...');
    setIsSubmitting(true);
    const result = await addUserWithPassword(values);
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    if (result && result.ok) {
      toast.update(toastId, {
        render: 'New user invited successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000 // Automatically close after 5 seconds
      });
      await wait(3000);
      navigate('/super/user-management');
    } else {
      let message;
      try {
        message = (await result.clone().json()).message;
      } catch (error) {
        message = await result.text();
      }

      if (!message) {
        message = 'An error occurred. Please try again later.';
      }

      toast.update(toastId, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000 // Automatically close after 5 seconds
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <Row>
        <Col sm={12} md={6}>
          <Card className="mb-3">
            <FalconCardHeader title="User Details" />
            <Card.Body>
              <Form.Group className="mb-3" controlId="isAdmin">
                <Form.Check
                  type="checkbox"
                  isInvalid={!!errors.isAdmin}
                  {...register('isAdmin')}
                  label="Is Admin"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.isAdmin?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>
                  Name <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>
                  E-mail <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  isInvalid={!!errors.email}
                  {...register('email')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.phone}
                  {...register('phone')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="country">
                <Form.Label>
                  Country <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Controller
                  name="country"
                  control={control}
                  defaultValue="Country/Region"
                  required
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      isSearchable={true}
                      name={name}
                      inputRef={ref}
                      value={{
                        label: countyCodes[value] ?? value,
                        value: value
                      }}
                      options={Object.entries(countyCodes).map(
                        ([key, value]) => ({ label: value, value: key })
                      )}
                      placeholder="Country/Region"
                      defaultValue="Country/Region"
                      classNamePrefix="react-select"
                      required
                      onChange={selectedOption =>
                        onChange(selectedOption.value)
                      }
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.country?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="postalCode">
                <Form.Label>
                  Postal Code
                  <TooltipBadge
                    tooltip="Stripe requires this field in some countries for tax purposes."
                    icon="question-circle"
                    placement="bottom"
                    color="secondary"
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.postalCode}
                  {...register('postalCode')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postalCode?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>
                  Username <span style={{ color: 'red' }}>*</span>
                  <TooltipBadge
                    tooltip="The Auth0 username must not contain '@' or special characters. The user can use their username or email to login."
                    icon="question-circle"
                    placement="bottom"
                    color="secondary"
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.username}
                  {...register('username')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>
                  Password <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <PasswordInput
                  isInvalid={!!errors.password}
                  {...register('password')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirm-password">
                <Form.Label>
                  Confirm Password <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <PasswordInput
                  isInvalid={!!errors.confirmPassword}
                  {...register('confirmPassword')}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} md={6}>
          <Card className="mb-3 col-6 col-sm-12">
            <FalconCardHeader title="MSP Details" />
            {!isAdmin ? (
              <Card.Body>
                {isLoading ? (
                  <Spinner animation="border" size="sm" role="Status">
                    <span className="visually-hidden">
                      Verifying MFA status...
                    </span>
                  </Spinner>
                ) : (
                  <Form.Group className="mb-3" controlId="msp.id">
                    <Form.Label>
                      MSP <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Controller
                      name="msp.id"
                      control={control}
                      defaultValue="Select MSP"
                      required
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          isSearchable={true}
                          name={name}
                          inputRef={ref}
                          value={{
                            label:
                              mspData.find(({ id }) => id === value)?.name ??
                              value,
                            value: value
                          }}
                          options={mspData.map(({ id, name }) => ({
                            label: name,
                            value: id
                          }))}
                          placeholder="Select MSP"
                          classNamePrefix="react-select"
                          required
                          onChange={selectedOption =>
                            onChange(selectedOption.value)
                          }
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.msp?.id?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Card.Body>
            ) : (
              <Card.Body>
                <Form.Group className="mb-3" controlId="msp.name">
                  <Form.Label>
                    MSP Name <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.msp?.name}
                    {...register('msp.name')}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.msp?.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="msp.address">
                  <Form.Label>MSP Address</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.msp?.address}
                    {...register('msp.address')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.msp?.address?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="msp.city">
                  <Form.Label>MSP City</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.msp?.city}
                    {...register('msp.city')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.msp?.city?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="msp.state">
                  <Form.Label>MSP State</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.msp?.state}
                    {...register('msp.state')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.msp?.state?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="msp.zipcode">
                  <Form.Label>MSP Zipcode</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.msp?.zipcode}
                    {...register('msp.zipcode')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.msp?.zipcode?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <Button className="w-100" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          'Create MSP'
        )}
      </Button>
    </Form>
  );
};

export default CreateMsp;
