import 'assets/css/navigation.css';
import paths from './paths';
import { faIdCard, faLifeRing } from '@fortawesome/free-solid-svg-icons';

export const navRoutes = {
  label: 'dashboards',
  labelDisable: true,
  children: [
    {
      name: 'Home',
      active: true,
      icon: 'home',
      to: paths.landing,
      exact: true
    },
    {
      name: 'Financial Risk Simulator',
      icon: 'chart-pie',
      to: paths.impactSimulator,
      active: true
    },
    {
      name: 'Organizations',
      icon: 'users',
      to: paths.clients,
      active: true
    },
    {
      name: 'Threat Academy',
      icon: 'graduation-cap',
      //create the reports page based on UI design
      to: paths.academy,
      active: false
    },
    {
      name: 'Reports',
      icon: 'chart-line',
      //create the reports page based on UI design
      to: paths.reports,
      active: false,
      badge: {
        type: 'info',
        text: 'Coming Soon'
      }
    }
  ]
};

export const adminRoutes = {
  label: 'dashboards',
  labelDisable: true,
  children: [
    {
      name: 'Home',
      icon: 'home',
      to: paths.superAdminLanding,
      active: true,
      exact: true
    },
    {
      name: 'Accounts',
      icon: faIdCard,
      to: paths.userManagement,
      active: true
    },
    {
      name: 'Permissions',
      icon: 'user-shield',
      to: paths.permissions,
      active: true
    },
    {
      name: 'Support Ticket',
      icon: faLifeRing,
      to: paths.support,
      active: true
    }
  ]
};

export default [navRoutes];
