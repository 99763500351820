import React, { useEffect, useMemo } from 'react';
import { Button, Form, Col, Row, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import TooltipBadge from 'components/common/TooltipBadge';
import {
  industries,
  breachTypes,
  countries
} from 'data/simulators/impactSimulatorData';
import { toast } from 'react-toastify';
import useGetClient from 'hooks/useGetClients';
import useIntake from 'hooks/useIntake';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowRight } from 'react-bootstrap-icons';

const schema = yup
  .object({
    clientType: yup.string().required('This field is required.'),
    prospectName: yup.string().when('clientType', {
      is: 'prospect',
      then: schema => schema.required('This field is required.'),
      otherwise: schema => schema.notRequired()
    }),
    clientName: yup.object().when('clientType', {
      is: 'client',
      then: () =>
        yup.object({
          value: yup.string().required('This field is required.')
        }),
      otherwise: () => yup.object({ value: yup.string().notRequired() })
    }),
    industry: yup
      .object({
        value: yup.string().required('This field is required.')
      })
      .required('This field is required.'),
    breachType: yup
      .object({
        value: yup.string().required('This field is required.')
      })
      .required('This field is required.'),
    country: yup
      .object({
        value: yup.string().required('This field is required.')
      })
      .required('This field is required.'),
    recordsLoss: yup.string().required('This field is required.'),
    bestRecordsPercent: yup
      .number()
      .required('This field is required.')
      .min(0, 'Value must be greater than 0.')
      .max(100, 'Value must be less than 100.'),
    worstRecordsPercent: yup
      .number()
      .required('This field is required.')
      .min(0, 'Value must be greater than 0.')
      .max(100, 'Value must be less than 100.')
  })
  .required();

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const ImpactSimulatorForm = ({
  setFormData,
  goToLikelihoodPage,
  impactResponse
}) => {
  const { fetchIntakeForClient } = useIntake();
  const {
    getClients,
    isLoading: isLoadingClients,
    error: errorLadingClients,
    response: clients
  } = useGetClient();

  useEffect(() => {
    if (!clients) {
      getClients();
    }
  }, [clients]);

  useEffect(() => {
    if (errorLadingClients) {
      toast.error('Error loading clients');
    }
  }, [errorLadingClients]);

  const filteredClients = useMemo(
    () =>
      clients?.filter(
        client => client.prospect === false && client.deleted === false
      ) ?? [],
    [clients]
  );

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      recordsLoss: 'best'
    }
  });

  const isProspect = watch('clientType', 'client') === 'prospect';
  const clientName = watch('clientName');
  const recordsEstimate = parseInt(watch('recordsEstimate'));
  const bestRecordsPercent = parseFloat(watch('bestRecordsPercent'));
  const worstRecordsPercent = parseFloat(watch('worstRecordsPercent'));
  const bestRecords = (
    ((!isNaN(recordsEstimate) ? recordsEstimate : 0) *
      (!isNaN(bestRecordsPercent) ? bestRecordsPercent : 0)) /
    100
  ).toFixed(0);
  const worstRecords = (
    ((!isNaN(recordsEstimate) ? recordsEstimate : 0) *
      (!isNaN(worstRecordsPercent) ? worstRecordsPercent : 0)) /
    100
  ).toFixed(0);

  useEffect(() => {
    if (clientName) {
      const selectedClient = filteredClients.find(
        client => client.id === clientName.value
      );
      if (selectedClient) {
        setValue('industry', {
          value: selectedClient.industry,
          label: selectedClient.industry
        });
        setValue('country', {
          value: selectedClient.country,
          label: selectedClient.country
        });
        fetchIntakeForClient(clientName.value)
          .then(intakeForm => {
            setValue('recordsEstimate', intakeForm.recordsEstimate ?? 0);
            setValue(
              'criticalLossThreshold',
              currencyFormatter.format(intakeForm.criticalLossThreshold || 0)
            );
            setValue(
              'emergencyResponseFund',
              currencyFormatter.format(intakeForm.emergencyResponseFund || 0)
            );
          })
          .catch(error => {
            console.error(error);
            toast.error("Error fetching client's intake form");
          });
      }
    }
  }, [clientName, filteredClients, setValue]);

  const onSubmit = data => {
    let minimumExposedRecords, maximumExposedRecords;
    if (data.recordsLoss === 'best') {
      minimumExposedRecords = Math.floor(bestRecords * 0.95);
      maximumExposedRecords = Math.floor(bestRecords * 1.05);
    } else {
      minimumExposedRecords = Math.floor(worstRecords * 0.95);
      maximumExposedRecords = Math.floor(worstRecords * 1.05);
    }

    setFormData({
      numberIterations: 1000000,
      minimumExposedRecords,
      maximumExposedRecords,
      isHardCostOnly: false,
      industryType: data.industry.value,
      country: data.country.value,
      breachType: data.breachType.value,
      mspClientId: !isProspect ? data.clientName.value : null,
      prospectName: isProspect ? data.prospectName : null,
      prospect: isProspect
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-3 mb-3">
        <Col xs={6} lg={2}>
          <Form.Check
            inline
            type="radio"
            label="Demo"
            name="clientType"
            {...register('clientType')}
            value="prospect"
            checked={isProspect}
          />
        </Col>
        <Col xs={6} lg={2}>
          <Form.Check
            type="radio"
            label="Existing Client"
            name="clientType"
            {...register('clientType')}
            value="client"
            checked={!isProspect}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={3}>
          {isProspect ? (
            <Form.Group className="mb-3">
              <Form.Label>Prospect Name</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.prospectName}
                {...register('prospectName')}
                placeholder="Prospect Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.prospectName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            // This is the part of the form that is validating
            //even when the clientType is 'prospect'.
            <Form.Group className="mb-3">
              <Form.Label>Client Name</Form.Label>
              {filteredClients ? (
                <Controller
                  control={control}
                  name="clientName"
                  render={({ field }) => (
                    <div>
                      <Select
                        classNamePrefix="react-select"
                        {...field}
                        options={filteredClients.map(client => ({
                          value: client.id,
                          label: client.name // Use client.name as the label
                        }))}
                        placeholder=""
                      />
                      {errors.clientName && (
                        <div className="invalid-feedback d-block">
                          {errors.clientName?.value?.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              ) : isLoadingClients ? (
                <Spinner animation="border" size="sm" role="Status">
                  <span className="visually-hidden">Loading clients...</span>
                </Spinner>
              ) : (
                <></>
              )}
            </Form.Group>
          )}
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Industry</Form.Label>
            <Controller
              control={control}
              name="industry"
              render={({ field }) => (
                <div>
                  <Select
                    classNamePrefix="react-select"
                    {...field}
                    options={industries.map(industry => ({
                      value: industry,
                      label: industry
                    }))}
                    placeholder=""
                  />
                  {errors.industry && (
                    <div className="invalid-feedback d-block">
                      {errors.industry?.value?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Breach Type</Form.Label>
            <Controller
              control={control}
              name="breachType"
              render={({ field }) => (
                <div>
                  <Select
                    classNamePrefix="react-select"
                    {...field}
                    options={breachTypes.map(type => ({
                      value: type,
                      label: type
                    }))}
                    placeholder=""
                  />
                  {errors.breachType && (
                    <div className="invalid-feedback d-block">
                      {errors.breachType?.value?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Country/Region</Form.Label>
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <div>
                  <Select
                    classNamePrefix="react-select"
                    {...field}
                    options={countries.map(country => ({
                      value: country,
                      label: country
                    }))}
                    placeholder=""
                  />
                  {errors.country && (
                    <div className="invalid-feedback d-block">
                      {errors.country?.value?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>
              Estimated Sensitive Data
              <TooltipBadge
                tooltip="An estimate of the sensitive data records your organization manages (e.g., customer info, financial data). 
                        This information is crucial for evaluating the potential risk and financial impact if a cybersecurity breach occurs."
                icon="question-circle"
                placement="bottom"
                color="secondary"
              />
            </Form.Label>
            <Form.Control
              type="number"
              defaultValue={0}
              isInvalid={!!errors.recordsEstimate}
              {...register('recordsEstimate')}
              placeholder="Sensitive Data Estimate"
            />
            <Form.Control.Feedback type="invalid">
              {errors.recordsEstimate?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Label htmlFor="bestRecordsPercent">Minor Data Loss</Form.Label>
          <Row className="g-3 mb-3">
            <Col sm={1}>
              <Form.Check
                inline
                type="radio"
                {...register('recordsLoss')}
                value="best"
                id="best-case-records"
              />
            </Col>
            <Col sm={5}>
              <InputGroup>
                <Form.Control
                  type="number"
                  defaultValue={10}
                  isInvalid={!!errors.bestRecordsPercent}
                  id="bestRecordsPercent"
                  {...register('bestRecordsPercent')}
                  placeholder="10"
                  aria-describedby="best-percent"
                  style={{ padding: '0.3125rem 0.7rem' }}
                />
                <InputGroup.Text id="best-percent">%</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={6}>
              <Form.Control type="number" value={bestRecords} readOnly />
            </Col>
            <Form.Control.Feedback type="invalid">
              {errors.bestRecordsPercent?.message}
            </Form.Control.Feedback>
          </Row>
          <Form.Label htmlFor="worstRecordsPercent">Major Data Loss</Form.Label>
          <Row className="g-3 mb-3">
            <Col sm={1}>
              <Form.Check
                inline
                type="radio"
                id="worst-case-records"
                {...register('recordsLoss')}
                value="worst"
              />
            </Col>
            <Col sm={5}>
              <InputGroup>
                <Form.Control
                  type="number"
                  defaultValue={90}
                  isInvalid={!!errors.worstRecordsPercent}
                  id="worstRecordsPercent"
                  {...register('worstRecordsPercent')}
                  placeholder="90"
                  aria-describedby="worst-percent"
                  style={{ padding: '0.3125rem 0.7rem' }}
                />
                <InputGroup.Text id="worst-percent">%</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={6}>
              <Form.Control type="number" value={worstRecords} readOnly />
            </Col>
            <Form.Control.Feedback type="invalid">
              {errors.worstRecordsPercent?.message}
            </Form.Control.Feedback>
          </Row>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>
              Critical Loss Threshold
              <TooltipBadge
                tooltip="At what financial loss point would your company no longer be able to operate?"
                icon="question-circle"
                placement="bottom"
                color="secondary"
              />
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={currencyFormatter.format(0.0)}
              {...register('criticalLossThreshold', {
                onBlur: e => {
                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');
                  e.target.value = currencyFormatter.format(rawValue);
                },
                setValueAs: value => {
                  if (!value) {
                    return '0';
                  }
                  return (value + '').replace(/[^0-9.]/g, '');
                }
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.criticalLossThreshold?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>
              Emergency Response Fund amount
              <TooltipBadge
                tooltip="What is the amount you can allocate to resolve an issue quickly?"
                icon="question-circle"
                placement="bottom"
                color="secondary"
              />
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={currencyFormatter.format(0.0)}
              {...register('emergencyResponseFund', {
                onBlur: e => {
                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');
                  e.target.value = currencyFormatter.format(rawValue);
                },
                setValueAs: value => {
                  if (!value) {
                    return '0';
                  }
                  return (value + '').replace(/[^0-9.]/g, '');
                }
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.emergencyResponseFund?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            variant="primary"
            className="w-100"
            disabled={!isValid}
          >
            <FontAwesomeIcon icon={faChartPie} className="me-2" />
            Assess Risk
          </Button>
          <Button
            type="button"
            variant="falcon-primary"
            className="w-100 mt-1"
            disabled={impactResponse === null}
            onClick={goToLikelihoodPage}
          >
            Evaluate Likelihood <ArrowRight />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
ImpactSimulatorForm.propTypes = {
  setFormData: PropTypes.func.isRequired,
  goToLikelihoodPage: PropTypes.func.isRequired,
  impactResponse: PropTypes.object
};
export default ImpactSimulatorForm;
