import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useTrial = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const startTrial = async (mspId, trialDays) => {
    setIsSubmitting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/subscription/create-trial`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${accessToken}`
          },
          body: new URLSearchParams({
            msp_id: mspId,
            trial_days: trialDays
          }).toString()
        }
      );
      return response;
    } finally {
      setIsSubmitting(false);
    }
  };
  return { startTrial, isSubmitting };
};

export default useTrial;
